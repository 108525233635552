<template>
  <div style="background: #CD3736;color: #000;height: 100%;">
    <van-nav-bar title="会员升级" :z-index="999" :fixed="true" left-arrow @click-left="back" />
    <div style="padding:46px 0px;background: #CD3736;">
      <img class="imgs" src="../../assets/img/libao.png" alt />
      <div style="text-align: center;margin-top: 15px;">
        <van-button class="bot" color="linear-gradient(180deg, #FAF5D8 0%, #F5C48C 100%)" v-preventReClick @click="zhifu">
          <span style="color: #d90000;">立刻下单</span>
        </van-button>
      </div>
      <div class="imgd">
        <img class="imgs" src="../../assets/img/libao88.png" alt />
      </div>
      <div class="imgd" style="  margin: 0 10px; margin-top: 18px;">
        <img class="imgs" src="../../assets/img/libaodi.png" alt />
      </div>
      <div class="imgd" style="  margin: 0 10px;">
        <img class="imgs" src="../../assets/img/libaoname.png" alt />
      </div>
    </div>
  </div>
</template>
<script>
import orderModel from "../../api/order";
import userModel from "../../api/user";
import preventReClick from "../../assets/preventReClick";
export default {
  data() {
    return {
      isWxMini: "",
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
    };
  },
  methods: {
    zhifu() {
      var _this = this;
      if (
        this.chexin == null ||
        this.chexin.carNo == "" ||
        this.chexin.carNo == null
      ) {
        _this.$toast("请先认证车牌再购买！");
        setTimeout(() => {
          window.location.href = "/authentication";
        }, 1000);

        return;
      }
      var province = this.shangjia.province
        ? this.shangjia.province.toString()
        : "";
      var city = this.shangjia.city ? this.shangjia.city.toString() : "";
      var district = this.shangjia.district
        ? this.shangjia.district.toString()
        : "";
      var address = this.shangjia.address
        ? this.shangjia.address.toString()
        : "";
      var areaDetail = province + city + district + address;
      var lis = {
        id: 43,
        activityPrice: 88,
        separateId: 2, //消费合伙人传1,88礼包穿2，399套餐传3，999套餐传4
        // activityPrice: 0.01,
        customerId: this.userInfo ? this.userInfo.customerId : "",
        gid: this.shangjia.id,
        payType: 1,
        type: 11,
        // shareId: this.shareId ? this.shareId : null,
        name: "88元大礼包",
        areaDetail: areaDetail, //维修厂详细地址
        carModel: this.chexin ? this.chexin.carBrand : "", //车型
        carNo: this.chexin ? this.chexin.carNo : "", //车牌
        garageName: this.shangjia.name, //修理厂名称
        positionJd: this.shangjia.positionJd,
        shareId: this.$route.query.cid ? this.$route.query.cid : null, //分享者的id
        shareFlag: 1, //分享提成标志 0:不是 1:是
        positionWd: this.shangjia.positionWd,
        tel: this.shangjia.legalPersonPhone,
        receiver: this.shangjia.legalPerson, //用户名称
      };
      var isWxMini = window.__wxjs_environment === "miniprogram";
      console.log(isWxMini);
      if (isWxMini) {
        lis.token = localStorage.getItem("Authorization");
        lis.ccisok = 4; //3:会员卡订单 4:套餐订单 5:充值卡订单
        let payDataStr = JSON.stringify(lis);
        wx.miniProgram.navigateTo({
          url: "/pages/pay/pay?payDataStr=" + payDataStr, // 微信小程序调用支付的页面
        });
      } else {
        orderModel.goumaivip(lis).then((res) => {
          if (res.code == 200) {
            if (typeof WeixinJSBridge === "undefined") {
              // 微信浏览器内置对象。参考微信官方文档
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data),
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
              }
            } else {
              console.log("准备调用微信支付");
              _this.onBridgeReady(res.data);
            }
          }
        });
      }
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign, // 微信签名
        },
        function (res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast.success("支付成功");
            setTimeout(() => {
              window.location.href =
                "/order_details?id=" +
                data.id +
                "&status=" +
                3 +
                "&type=" +
                4 +
                "&rowtype=" +
                0 +
                "&isok=" +
                true;
              //   vm.$router.go(-1);
            }, 1000);
          } else {
            vm.$toast("支付失败");
          }
        }
      );
    },
    back() {
      this.$router.go(-1);
    },
    getConfig() {
      let that = this;
      userModel
        .config({
          url: location.href.split("#")[0],
          gid: sessionStorage.getItem("Agid"),
        })
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: ["onMenuShareAppMessage"],
            });
            if (this.$route.query.cid != undefined) {
              var link =
                "https://shopping.car-posthouse.cn/gift_bag?gid=" +
                sessionStorage.getItem("gid") +
                "&cid=" +
                this.$route.query.cid +
                "&appid=" +
                sessionStorage.getItem("appid") +
                "&denglu=" +
                true;
            } else {
              var link =
                "https://shopping.car-posthouse.cn/gift_bag?gid=" +
                sessionStorage.getItem("gid") +
                "&cid=" +
                this.userInfo.id +
                "&appid=" +
                sessionStorage.getItem("appid") +
                "&denglu=" +
                true;
            }

            wx.ready(() => {
              wx.onMenuShareAppMessage({
                title: this.shangjia.name,
                desc: "88元大礼包",
                link: link,
                imgUrl:
                  "http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/7236/1629273395523libao.png?Expires=1944633395&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=VZCHx4raNqcTBWSxtyA63MdXy%2Bc%3D",
                success: function () {},
              });
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
  },
  created() {
    // this.onLoad();
    if (this.$route.query.denglu != undefined) {
      var url =
        this.$route.path +
        "?gid=" +
        this.$route.query.gid +
        "&id=" +
        this.$route.query.id +
        "&cid=" +
        this.$route.query.cid;
      localStorage.setItem("beforeUrl", url);
      sessionStorage.setItem("Agid", this.$route.query.gid);
      sessionStorage.setItem("gid", this.$route.query.gid);
      sessionStorage.setItem("cid", this.$route.query.cid);
      userModel
        .getGarageInfo({ gid: this.$route.query.gid })
        .then((e) => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          // window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        })
        .catch(() => loading.clear());
    }
    var isWxMini = window.__wxjs_environment === "miniprogram";
    this.isWxMini = isWxMini;
    this.getConfig();
  },
};
</script>
<style lang="less" scoped>
.imgs {
  width: 100%;
  display: block;
}
.imgd {
  margin: 0 12px;
  margin-top: 18px;
}
.bot {
  box-shadow: 0 3px 5px 0 rgba(138, 0, 0, 0.4);
  border-radius: 22.5px;
  width: 188px;
  height: 45px;
  font-family: HYZhuZiMeiXinTiW;
  font-size: 22px;
  font-weight: bold;

  letter-spacing: 0;
}
</style>